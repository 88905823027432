import { useExcludePart, useIncludePart } from '@assemblio/frontend/data-access';
import { ModelController, useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface AssemblyExIncludeButtonProps {
  assemblyContext: number;
}

export const AssemblyExIncludeButton = ({ assemblyContext }: AssemblyExIncludeButtonProps) => {
  const isAssemblyExcluded = ModelController.isAssemblyExcluded(assemblyContext);
  const selectedParts = useUIStore((state) => state.selectedPartSet);
  const partExcludeMutation = useExcludePart();
  const partIncludeMutation = useIncludePart();

  const setAssemblyExcludedProperty = (exclude: boolean) => {
    const selectedSet = selectedParts.size > 0 ? selectedParts : useUIStore.getState().selectedExcludedPartSet;

    const { status, excludedIndices } = ModelController.setAssemblyExcluded(assemblyContext, exclude);
    const excludedSelectionIndices = ModelController.setPartsExcluded(Array.from(selectedSet), exclude);
    const currentSequenceId = useSequenceStore.getState().selectedSequenceId;

    const exclusionTargets = Array.from(new Set([...excludedIndices, ...excludedSelectionIndices]));

    const catchFunction = () => {
      notifications.show({
        id: 'exclude-assembly',
        message: "Couldn't change excluded state of parts",
        color: 'red',
      });

      ModelController.setPartsExcluded(exclusionTargets, !exclude);
    };

    if (exclude) {
      partExcludeMutation.mutate(
        { id: currentSequenceId, data: { gltfIndices: exclusionTargets } },
        {
          onSuccess: () => showSuccessNotification(exclude, status),
          onError: () => catchFunction(),
        }
      );
    } else {
      partIncludeMutation.mutate(
        { id: currentSequenceId, data: { gltfIndices: exclusionTargets } },
        {
          onSuccess: () => showSuccessNotification(exclude, status),
          onError: () => catchFunction(),
        }
      );
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Button
        variant="default"
        onClick={() => {
          setAssemblyExcludedProperty(false);
        }}
        style={{ marginBottom: '5px' }}
        disabled={isAssemblyExcluded === 'nothing'}
      >
        Include
      </Button>
      <Button
        variant="default"
        onClick={() => {
          setAssemblyExcludedProperty(true);
        }}
        disabled={isAssemblyExcluded === 'complete'}
      >
        Exclude
      </Button>
    </>
  );
};

const showSuccessNotification = (exclude: boolean, status: ModelController.AssemblyExclusionResultStatus) => {
  if (status !== 'nothing') {
    notifications.show({
      id: 'exclude-assembly',
      message: `${exclude ? 'Excluded' : 'Included'} ${status} Assembly`,
      color: status === 'complete' ? 'green' : 'yellow',
    });
  }
};
