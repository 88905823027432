import { produce } from 'immer';
import { ImperativeModelController, ModelVisibilityController, StepController, UIController } from '..';
import { Model, useModelStore } from '../../stores/ModelStore';
import { MachineController } from '../MachineController';
import { ProjectController } from '../ProjectController';

export type AssemblyExclusionResultStatus = 'complete' | 'nothing' | 'partial';

interface AssemblyExclusionResult {
  status: AssemblyExclusionResultStatus;
  excludedIndices: number[];
}

export const isModelExcluded = (gltfIndex: number): boolean => {
  const modelInformation = useModelStore.getState().modelInformationMap.get(gltfIndex);
  return modelInformation !== undefined && modelInformation.excluded;
};

export const isPartExcluded = isModelExcluded;

export const isAssemblyExcluded = (gltfIndex: number): AssemblyExclusionResultStatus => {
  const allPartGLTFIndices = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(gltfIndex, true);

  const hasTrue = allPartGLTFIndices.some((partGltfIndex) => isPartExcluded(partGltfIndex));
  const hasFalse = allPartGLTFIndices.some((partGltfIndex) => !isPartExcluded(partGltfIndex));

  if (hasTrue && hasFalse) return 'partial';
  if (hasTrue) return 'complete';
  else return 'nothing';
};

export const setPartsExcluded = (gltfIndices: number[], exclude: boolean): number[] => {
  // Filter out indices that are in a step and should be excluded
  const validGltfIndices = gltfIndices.filter((gltfIndex) => !(StepController.hasStep(gltfIndex) && exclude));

  if (validGltfIndices.length === 0) return [];

  useModelStore.setState(
    produce<Model>((state) => {
      validGltfIndices.forEach((gltfIndex) => {
        const modelInformation = state.modelInformationMap.get(gltfIndex);

        if (modelInformation) {
          modelInformation.excluded = exclude;
        }
      });
    })
  );

  validGltfIndices.forEach((gltfIndex) => {
    if (!exclude) {
      ModelVisibilityController.setPartVisible(gltfIndex, true);
      ModelVisibilityController.setPartTransparent(gltfIndex, false);
    } else {
      ImperativeModelController.excludeModel(gltfIndex);
    }

    updateParentExclusionStatus(gltfIndex, exclude);
  });

  UIController.deselectExcludedParts();
  MachineController.deselect();

  return validGltfIndices;
};

export const setAssemblyExcluded = (gltfIndex: number, exclude: boolean): AssemblyExclusionResult => {
  const partGltfIndices = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(gltfIndex, true);

  const validGltfIndices = partGltfIndices.filter((gltfIndex) => {
    const isExcluded = isPartExcluded(gltfIndex);
    return exclude ? !isExcluded : isExcluded;
  });

  const excludedIndices = setPartsExcluded(validGltfIndices, exclude);

  const complete = validGltfIndices.every((gltfIndex) => excludedIndices.includes(gltfIndex));
  const status = excludedIndices.length === 0 ? 'nothing' : complete ? 'complete' : 'partial';
  return { status, excludedIndices };
};

const updateParentExclusionStatus = (gltfIndex: number, exclude: boolean) => {
  const parent = ProjectController.getParent(gltfIndex);
  if (parent === undefined) return;

  let parentStatusChanged = false;
  useModelStore.setState(
    produce<Model>((state) => {
      const parentModelInfo = state.modelInformationMap.get(parent);

      if (parentModelInfo) {
        const excludedResult = isAssemblyExcluded(parent);

        if (excludedResult === 'complete') {
          if (parentModelInfo.excluded !== exclude) {
            parentModelInfo.excluded = true;
            parentStatusChanged = true;
          }
        } else if (excludedResult === 'nothing' || excludedResult === 'partial') {
          if (parentModelInfo.excluded !== exclude) {
            parentModelInfo.excluded = false;
            parentStatusChanged = true;
          }
        }
      }
    })
  );

  if (parentStatusChanged) {
    updateParentExclusionStatus(parent, exclude);
  }
};
