import { IconCube } from '@assemblio/design-system';
import { MachineController, UIController } from '@assemblio/frontend/stores';
import { Part } from '@assemblio/type/input';
import cx from 'clsx';
import { LightWeightIcon } from './LightWeightIcon';
import ToggableTextInput from './ToggableTextInput';
import classes from './TreeItem.module.scss';
import { TreeItemActions } from './TreeItemActions';
import { hierarchyEqualityFunction, useHierarchyStore } from './hierarchy.store';
import { Tree } from '../types/hierarchy.types';

interface Props {
  tree: Tree;
}

export const HierarchyLeaf = ({ tree }: Props) => {
  const part = tree.data as Part;
  const leafData = useHierarchyStore((state) => state.elements.get(part.gltfIndex), hierarchyEqualityFunction);
  if (!leafData) return null;
  const { selected, name, visible, excluded, transparent, filtered } = leafData;

  const select = (gltfIndex: number) => {
    MachineController.selectTransition(gltfIndex);
  };

  return (
    <div
      style={{ display: filtered ? 'none' : '' }}
      key={`part-${part.gltfIndex}`}
      className={cx(classes.itemWrapper, {
        [classes.selected]: selected,
      })}
      data-content={name}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (e.button === 0) {
          if (excluded) {
            MachineController.deselect();
            UIController.selectExcludedPartsWithMode(part.gltfIndex);
          } else {
            UIController.deselectExcludedParts();
            select(part.gltfIndex);
          }
        }
      }}
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        UIController.setPartContext(part.gltfIndex);
      }}
    >
      <LightWeightIcon className={classes.leaf_icon}>
        <IconCube />
      </LightWeightIcon>
      <div className={classes.mid}>
        <ToggableTextInput
          elementId={part.id}
          isLeaf={true}
          gltfIndex={part.gltfIndex}
          name={name}
          visible={visible}
          excluded={excluded}
        />
      </div>
      {!excluded && (
        <div
          className={cx(classes.rhs, {
            [classes.rhs__selected]: selected || visible,
          })}
        >
          <TreeItemActions isLeaf={true} assembly={part} visible={visible} transparent={transparent} />
        </div>
      )}
    </div>
  );
};
