import {
  ModelController,
  ModelInformationMap,
  ModelVisibilityController,
  ProjectController,
  StepController,
  UIController,
  useModelStore,
  useProjectStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Assembly, AssemblyMetaData, Part } from '@assemblio/type/input';
import produce from 'immer';
import { FilterResult, HierarchyElement, HierarchyFilters, SearchTerm, Tree } from '../types/hierarchy.types';
import { HierarchyStore, useHierarchyStore } from './hierarchy.store';
import { stepEvents } from '@assemblio/frontend/events';
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HierarchyController {
  /* Find the index of the assembly that doesn't have a parent, which is the root of the hierarchy tree */
  const findRoot = (): number => {
    const assemblyMetaData: AssemblyMetaData = useProjectStore.getState().input;

    if (assemblyMetaData.assemblies.length === 1) {
      return 0;
    }

    const keys = assemblyMetaData.assemblies
      .map((assembly) => assembly.assemblies)
      .reduce((previous, current) => previous.concat(current), []);

    for (let i = 0; i < assemblyMetaData.assemblies.length; i++) {
      if (!keys.includes(i)) {
        return i;
      }
    }
    return -1;
  };

  /* Traverses the tree with a callback function for every element */
  const traverseTree = (tree: Tree, callback: (item: Tree) => void) => {
    callback(tree);
    tree.children.forEach((child) => {
      if (child.isLeaf) {
        callback(child);
      } else {
        traverseTree(child, callback);
      }
    });
  };

  export const init = () => {
    useHierarchyStore.getState().reset();
    const rootIndex = findRoot();

    initTree(rootIndex);

    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.rootIndex = rootIndex;
        const elements = new Map<number, HierarchyElement>();
        traverseTree(useHierarchyStore.getState().tree, (item) => {
          const isExcluded = useModelStore.getState().modelInformationMap.get(item.data.gltfIndex)?.excluded === true;
          elements.set(item.data.gltfIndex, {
            filtered: false,
            excluded: isExcluded,
            selected: false,
            visible: true,
            transparent: false,
            assembled: false,
            expanded: true,
            isLeaf: item.isLeaf,
            name: item.data.name,
            parent: item.parent,
            treeElement: item,
          });
        });
        state.elements = elements;
      })
    );
  };

  export const subscribeHierarchyUpdates = () => {
    const stepTypeUpdateCallback = () => applyFilter(useHierarchyStore.getState().hierarchyFilter);
    stepEvents.addEventListener('updateStepType', stepTypeUpdateCallback);

    const unsubSelectedParts = useUIStore.subscribe(
      (state) => [...state.selectedPartSet, ...state.selectedExcludedPartSet],
      (selectedParts) => {
        applySelectedParts(new Set(selectedParts));
      },
      { fireImmediately: true }
    );

    const unsubExpanded = useUIStore.subscribe(
      (state) => state.expandedAssemblies,
      (expandedAssemblies) => {
        applyExpandedAssemblies(expandedAssemblies);
      },
      { fireImmediately: true }
    );

    const unsubModelInformation = useModelStore.subscribe(
      (state) => state.modelInformationMap,
      (modelInformation) => {
        applyModelInformation(modelInformation);
      },
      { fireImmediately: true }
    );

    const unsubFilter = useHierarchyStore.subscribe(
      (state) => state.hierarchyFilter,
      (filter) => applyFilter(filter)
    );

    return () => {
      unsubSelectedParts();
      unsubExpanded();
      unsubModelInformation();
      unsubFilter();
      stepEvents.removeEventListener('updateStepType', stepTypeUpdateCallback);
    };
  };

  /* Generates the tree structure that is passed to the Hierarchy Branch and Hierarchy Leaf components */
  const initTree = (rootIndex: number) => {
    const assemblyMetaData: AssemblyMetaData = useProjectStore.getState().input;
    const traverse = (rootIndex: number, parent = -1): Tree | undefined => {
      const rootAssembly = assemblyMetaData.assemblies.at(rootIndex);
      if (rootAssembly) {
        const children = rootAssembly.assemblies
          .map((assemblyIndex) => {
            return traverse(assemblyIndex, rootIndex);
          })
          .concat(
            rootAssembly.parts.map((partIndex) => {
              const part = assemblyMetaData.parts.at(partIndex);
              if (part) {
                return {
                  data: part,
                  parent: rootIndex,
                  isLeaf: true,
                  children: new Array<Tree>(),
                };
              }
              return undefined;
            })
          )
          .filter((child) => child !== undefined) as Array<Tree>;
        return {
          data: rootAssembly,
          isLeaf: false,
          parent,
          children,
        };
      }
      return;
    };
    const tree = traverse(rootIndex);
    if (tree) {
      useHierarchyStore.setState(
        produce<HierarchyStore>((state) => {
          state.tree = tree;
        })
      );
    }
  };

  const applySelectedParts = (selectedParts: Set<number>) => {
    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.elements = Array.from(state.elements).reduce((map, [key, value]) => {
          if (value.isLeaf) {
            map.set(key, { ...value, selected: selectedParts.has(key) });
          } else {
            const assembly = ProjectController.getAssemblyByGltfIndex(key);
            if (assembly) {
              map.set(key, { ...value, selected: ModelController.isAssemblySelected(assembly) });
            }
          }
          return map;
        }, new Map<number, HierarchyElement>());
      })
    );
  };

  const applyExpandedAssemblies = (expandedAssemblies: Set<number>) => {
    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.elements = Array.from(state.elements).reduce((map, [key, value]) => {
          map.set(key, { ...value, expanded: expandedAssemblies.has(key) });
          return map;
        }, new Map<number, HierarchyElement>());
      })
    );
  };

  const applyModelInformation = (modelInformation: ModelInformationMap) => {
    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.elements = Array.from(state.elements).reduce((map, [gltfIndex, value]) => {
          const information = modelInformation.get(gltfIndex);
          if (!information) return map;
          const data = {
            name: information.name,
            excluded: information.excluded,
          };
          if (information.type === 'part') {
            map.set(gltfIndex, {
              ...value,
              ...data,
              visible: information.visible,
              transparent: information.transparent,
            });
          } else {
            map.set(gltfIndex, {
              ...value,
              ...data,
              visible: ModelVisibilityController.isAssemblyVisible(gltfIndex),
              transparent: ModelVisibilityController.isAssemblyTransparent(gltfIndex),
            });
          }
          return map;
        }, new Map<number, HierarchyElement>());
      })
    );
    applyFilter(useHierarchyStore.getState().hierarchyFilter);
  };

  const applyFilter = (filter: HierarchyFilters & SearchTerm) => {
    const assemblyMetaData: AssemblyMetaData = useProjectStore.getState().input;

    const filterMethod = (element: Part | Assembly, isLeaf: boolean): FilterResult => {
      const isExcluded = useModelStore.getState().modelInformationMap.get(element.gltfIndex)?.excluded;

      if (!filter.showExcluded && isExcluded) {
        return 'STOP';
      }

      const matchesSearch = element.name.toLowerCase().includes(filter.searchTerm.trim().toLowerCase());
      if (!matchesSearch) return 'EXCLUDE';

      const isDisassembled = isLeaf
        ? StepController.isPartDisassembled(element.gltfIndex)
        : StepController.isAssemblyDisassembled(element.gltfIndex);

      if (filter.showDisassembled || !isDisassembled) {
        if (filter.showExcluded || !isExcluded) {
          return 'INCLUDE';
        }
      }

      return 'EXCLUDE';
    };

    const setFiltered = (gltfIndex: number, filtered: boolean) => {
      useHierarchyStore.setState(
        produce<HierarchyStore>((state) => {
          const element = state.elements.get(gltfIndex);
          if (element) {
            element.filtered = filtered;
          }
        })
      );
    };

    const filterTree = (assemblyIndex: number): number => {
      const currentRoot = assemblyMetaData.assemblies.at(assemblyIndex);
      if (!currentRoot) return 0;
      const partCount = currentRoot.parts.reduce((count, partIndex) => {
        const part = assemblyMetaData.parts.at(partIndex);
        if (!part) return count;
        const filterResult = filterMethod(part, true);
        if (filterResult === 'INCLUDE') {
          setFiltered(part.gltfIndex, false);
          return count + 1;
        } else {
          setFiltered(part.gltfIndex, true);
          return count;
        }
      }, 0);
      const assemblyCount = currentRoot.assemblies.reduce((count, assemblyIndex) => {
        const childCount = filterTree(assemblyIndex);
        return count + childCount;
      }, 0);
      const childSum = partCount + assemblyCount;
      const filterResult = filterMethod(currentRoot, false);
      if (filterResult !== 'INCLUDE' && childSum === 0) {
        setFiltered(currentRoot.gltfIndex, true);
        return 0;
      }
      if (filterResult === 'INCLUDE' || childSum > 0) {
        setFiltered(currentRoot.gltfIndex, false);
        return 1;
      }
      setFiltered(currentRoot.gltfIndex, true);
      return 0;
    };

    filterTree(useHierarchyStore.getState().rootIndex);
  };

  export const changeExpansionBelow = (gltfIndex: number, expanded: boolean) => {
    const element = useHierarchyStore.getState().elements.get(gltfIndex);
    if (element) {
      const changedAssemblies = new Array<number>();
      traverseTree(element.treeElement, (item) => {
        if (!item.isLeaf) {
          changedAssemblies.push(item.data.gltfIndex);
        }
      });
      if (expanded) {
        UIController.expandAssemblies(changedAssemblies);
      } else {
        UIController.collapseAssemblies(changedAssemblies);
      }
    }
  };

  export const setFilter = (showExcluded: boolean, showDisassembled: boolean): void => {
    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.hierarchyFilter.showExcluded = showExcluded;
        state.hierarchyFilter.showDisassembled = showDisassembled;
      })
    );
  };

  export const setSearchTerm = (searchTerm: string): void => {
    useHierarchyStore.setState(
      produce<HierarchyStore>((state) => {
        state.hierarchyFilter.searchTerm = searchTerm;
      })
    );
  };
}
