import { useExcludePart, useIncludePart } from '@assemblio/frontend/data-access';
import { ModelController, StepController, useSequenceStore, useUIStore } from '@assemblio/frontend/stores';
import { Button, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';

interface PartExIncludeButtonProps {
  modelContext: number;
  onClose?: () => void;
}

export const PartExIncludeButton = ({ modelContext, onClose }: PartExIncludeButtonProps) => {
  const isPartExcluded = ModelController.isPartExcluded(modelContext);
  const selectedParts = useUIStore((state) => state.selectedPartSet);
  const partExcludeMutation = useExcludePart();
  const partIncludeMutation = useIncludePart();
  const [hasSteps, setHasSteps] = useState(false);

  useEffect(() => {
    let hasStep = false;
    if (modelContext !== undefined) {
      hasStep = StepController.hasStep(modelContext);
    }
    if (selectedParts.size > 0) {
      hasStep ||= Array.from(selectedParts).some((gltfIndex) => StepController.hasStep(gltfIndex));
    }
    setHasSteps(hasStep);
  }, [modelContext, selectedParts]);

  const setPartExcludedProperty = (exclude: boolean) => {
    const selectedSet = selectedParts.size > 0 ? selectedParts : useUIStore.getState().selectedExcludedPartSet;
    const exclusionTargets = Array.from(new Set([...selectedSet, modelContext]));
    const wasExcludedSet = ModelController.setPartsExcluded(exclusionTargets, exclude);
    const currentSequenceId = useSequenceStore.getState().selectedSequenceId;

    if (exclusionTargets.every((target) => wasExcludedSet.includes(target))) {
      const catchFunction = () => {
        notifications.show({
          id: 'exclude-part',
          message: "Couldn't change excluded state of part",
          color: 'red',
        });

        ModelController.setPartsExcluded(exclusionTargets, !exclude);
      };

      if (exclude) {
        partExcludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: exclusionTargets } },
          { onError: () => catchFunction() }
        );
      } else {
        partIncludeMutation.mutate(
          { id: currentSequenceId, data: { gltfIndices: exclusionTargets } },
          { onError: () => catchFunction() }
        );
      }
    } else {
      notifications.show({
        id: 'exclude-part',
        message: "Couldn't change excluded state of part. It may be already used in a step",
        color: 'red',
      });
      ModelController.setPartsExcluded(exclusionTargets, !exclude);
    }
  };
  return (
    <Tooltip
      label={
        hasSteps
          ? 'Part cannot be excluded, because it is already used in the instruction.'
          : 'Exclude the part from this instruction.'
      }
      position="bottom"
      color={!hasSteps ? '' : 'red'}
    >
      <Button
        variant="default"
        disabled={hasSteps}
        onClick={() => {
          setPartExcludedProperty(!isPartExcluded);
          onClose && onClose();
        }}
      >
        {isPartExcluded ? 'Include' : 'Exclude'}
      </Button>
    </Tooltip>
  );
};
